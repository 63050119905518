.log {
  width: 180px;
  height: 78.966px;
  flex-shrink: 0;
  margin-left: 50px;
  margin-top: 50px;
}
.header {
  display: flex;
  justify-content: center;
}

.List .MuiTablePagination-root {
  display: block;
}

.row {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  height: 150px;
}
.bg {
  height: 300px;
}

:root {
  background-color: #f3f3f3;
}
.name {
  margin-top: 50px;
  color: #2c6ab1;

  font-family: Cardo;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}

.date {
  opacity: 0.9;
  color: rgba(44, 106, 177, 0.5);
  font-family: Cardo;
  font-size: 25px;

  margin-left: 10px;
}

:root {
  width: 100%;
}
.cont {
  border-radius: 25.82px;
  border: 2.582px solid rgba(0, 0, 0, 0.09);
  background-color: white;
  width: 1200px;
  margin-top: 50px;
  height: 67vh;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border: 3px solid rgba(0, 0, 0, 0.1);
}

.table {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.9) 0.1;

  background: #d9d9d9 0.1;
  height: 1000px;
  width: 970px;

  margin: 0 auto;
}

.buttons {
  width: fit-content;
  height: 34px;
  text-align: center;
  border-radius: 41.38px;
  border: 1.138px solid rgba(0, 0, 0, 0.3);
  color: black;
  background: rgba(235, 236, 237, 1);

  &:disabled {
    color: rgb(183, 176, 175);
    cursor: auto;
  }
}

.download-icon-button {
  border: none;
  background: none;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin: 0;
  --tooltip-visibility: hidden;
  --tooltip-opacity: 0;

  &:hover {
    --tooltip-visibility: auto;
    --tooltip-opacity: 1;
  }
}

.download-icon-button .csv-tooltip-text {
  visibility: var(--tooltip-visibility);
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: var(--tooltip-opacity);
  transition: opacity 0.3s;

}

.exam-status-buttons {
  width: fit-content;
  height: 34px;
  text-align: center;
  border-radius: 41.38px;
  border: none;
  color: #2C6AB1;
  background: rgba(235, 236, 237, 1);
  cursor: pointer;
  margin-top: 10px;
  &:disabled {
    color: rgb(183, 176, 175);
    cursor: auto;
  }
}

.head {
  color: #2c6ab1;
  opacity: 0.9;
  font-family: Cardo;
  font-size: 25.352px;
  font-style: italic;
  font-weight: 400;
  padding: 12px;
  white-space: nowrap;
  line-height: 137.4%;
  text-align: left;
}
/*
.MuiDataGrid-root {
  align-items: center;
}
*/

.List .colHeaderStyles {
  color: #808080;
  font-family: Cardo;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}
.List .MuiButtonBase-root{
  width: 50px;
}


.List .MuiDataGrid-columnHeaderTitleContainer {
  align-items: center;
  justify-content: center;
}

.List .MuiDataGrid-iconButtonContainer {
  width: fit-content;
}

.MuiDataGrid-footer {
  display: none;
}

.students-download-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  gap: 5px;

}


.invite-button {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  width: fit-content;
  cursor: pointer;
  align-self: baseline;
  margin-top: 5px;
  background: rgba(235, 236, 237, 1);
  color: rgb(44, 106, 177);
  &:disabled {
    cursor: auto;
    color: rgb(183, 176, 175);
  }
}

/* .download-icon-button:hover::after {

  content: "Tooltip text";
  
  position: absolute;
  
  background-color: black;
  
  color: white;
  
  padding: 5px;
  
  border-radius: 4px;
  
  font-size: 14px;
  
  top: 100%;
  
  left: 50%;
  
  transform: translateX(-50%);
  
  white-space: nowrap;
  
  }
  
  .download-icon-button:hover::before {
  
  content: "";
  
  position: absolute;
  
  border-width: 6px;
  
  border-style: solid;
  
  border-color: black transparent transparent transparent;
  
  top: -12px;
  
  left: 50%;
  
  transform: translateX(-50%);
  
  } */

@font-face {
  font-family: "Cardo";
  src: url("../Assets/Fonts/Cardo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.school {
  color: #259e39;
}

button {
  display: flex;
  width: 175px;
  height: 38px;
  padding: 7.51px 13.143px;
  justify-content: center;
  align-items: center;
  gap: 9.388px;
  flex-shrink: 0;
  margin-top: 20px;

  background: #2c6ab1;
  font-size: large;
  color: white;
  border-radius: 41.38px;
  border: 1.138px solid rgba(0, 0, 0, 0.05);
}

.contain {
  flex-direction: row;
  display: flex;
  justify-content: space-between !important;
}

.new {
  margin-left: 600px;
}

.selector-text {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
}

.dropdown-texts {
  font-size: 18px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(235, 236, 237, 0.3);
  color: #2c6ab1;
  width: 237px;
  height: 42px;
  margin: auto;
}

.input {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: auto;
  background: rgba(235, 236, 237, 0.3);
  color: #2c6ab1;
  width: 257px;
  height: 42px;
}

.modal-inputs {
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: center;
  ::placeholder {
    font-size: 16px;
  };
}

.input-texts {
  font-family: Cardo;
  text-align: center;
  color: rgb(44, 106, 177);
  font-size: 18px;
}

.error-texts {
  color: red;
  font-size: 18px;
  text-align: center;
}

.warning-text-invite {
  color: red;
  font-size: 20px;
}

.warning-text-light {
  color: red;
  font-size: 20px;
  opacity: 0.7;
}

.invite-buttons-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.logo-button{
  border: none;
 
 margin-left: 10px;
 margin-top: 6px;
  height: 20px;
  width: 20px;
}

.form-placeholder{
  font-size: 15px;
}

.List .down{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: auto;
  position: absolute;
  bottom: 0;
  left: 28vw;
  z-index: -10;
}

.List .footer-container {
  display: flex;
}

.List .footer-text {
  color: rgb(183, 176, 175);
}

.student-details-buttons-wrapper {
  display: flex;
  gap: 10px;
}